import { faEdit, faInr } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Container, Row, Tab, Tabs } from 'react-bootstrap';
import SiteStageTab from './SiteStageTab';
import SiteDocumentListTable from './SiteDocumentListTable';
import InventoryListTable from './InventoryListTable';
import RequestForMaterialListTable from './RequestForMaterialListTable';
import SiteExpenseListTable from './SiteExpenseListTable';
import SitBillingTab from './SitBillingTab';
import { getService } from 'services/services';
import { Link, useParams } from 'react-router-dom';
import { Site } from 'data/project-management/projects';
import ChangeContractorModal from 'components/modals/ChangeContractorModal';
import SitePerformance from './SitePerformance';
import { dateFormat, formatDate, validationErrorFromAPI } from 'helpers/utils';
import { Col } from 'react-bootstrap';
import AttendanceListView from './AttendanceListView';
import AddStaff from './AddStaff';

const initialSiteDetails: Site = {};

interface Progres {
  total_stage: number;
  total_stage_complete: number;
}

const SiteDetails = () => {
  const [siteDetails, setSiteDetails] = useState<Site>(initialSiteDetails);
  const [progres, setProgres] = useState<Progres>();
  const { id, progress, budget } = useParams();
  const [activeKey, setActiveKey] = useState<string>('Site Stage');
  const [showAlert, setShowAlert] = useState(false);
  const [role, setRole] = useState(0);
  const siteName = siteDetails?.site_name || '';

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const contractorFullName =
    (siteDetails?.contractor?.first_name ?? '') +
    ' ' +
    (siteDetails?.contractor?.last_name ?? '');

  const contractorId = siteDetails?.contractor?.id ?? '';

  const fetch = async () => {
    const response = await getService(`site/${id}`);
    if (response.status === 200) {
      setSiteDetails(response.data.detail);
      setProgres(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };
  useEffect(() => {
    fetch();
  }, [showAlert]);

  const handleSelect = (key: string | null) => {
    if (key) {
      setActiveKey(key);
    }
  };

  const activeTabStyle = {
    backgroundColor: '#3874FF',
    color: 'white',
    fontSize: '20px'
  };
  const inactiveTabStyle = {
    // backgroundColor: 'white',
    // color: 'black',
    fontSize: '20px'
  };

  return (
    <Container fluid>
      <div>
        <Row className="justify-content-between align-items-center">
          <Col xs={12} md={6} className="px-2">
            <h5>Site Details</h5>
          </Col>
          <Col xs={12} md={6} className="text-end px-2">
            {role === 1 && (
              <Link className="btn btn-primary" to={`/site/editSite/${id}`}>
                Edit Site <FontAwesomeIcon icon={faEdit} className="ms-2" />
              </Link>
            )}
          </Col>
        </Row>
        <Container className="mt-4">
          <Row className="justify-content-around">
            <Col xs={12} md={6} lg={3} className="mb-3">
              <h6>Client Name: {siteDetails?.workorder?.company?.name}</h6>
              <h6>
                Contractor:{' '}
                {siteDetails?.contractor
                  ? `${siteDetails.contractor.first_name} ${siteDetails.contractor.last_name}`
                  : ''}{' '}
                {role === 1 && (
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => setShowAlert(true)}
                  />
                )}
              </h6>
            </Col>

            <Col xs={12} md={6} lg={3} className="mb-3">
              {role === 1 && (
                <h6>
                  WO ID/Name: {siteDetails?.workorder?.wo_id}/
                  {siteDetails?.workorder?.name}
                </h6>
              )}
              <div>
                <h6>Start date: {formatDate(siteDetails?.start_date)}</h6>
                <h6>Estimated date: {formatDate(siteDetails?.end_date)}</h6>
              </div>
            </Col>

            <Col xs={12} md={6} lg={3} className="mb-3">
              <h6>
                Site ID/Name: {siteDetails?.site_id}/{siteDetails?.site_name}
              </h6>
              <h6>
                Budget: <FontAwesomeIcon icon={faInr} /> {siteDetails?.budget}
              </h6>
            </Col>

            <Col xs={12} md={6} lg={3} className="mb-3">
              <h6>Site Address: {siteDetails?.address}</h6>
              <h6>
                Progress:{' '}
                {progres?.total_stage_complete && progres?.total_stage
                  ? `${(
                      (progres.total_stage_complete / progres.total_stage) *
                      100
                    ).toFixed(2)}%`
                  : '0.00%'}
              </h6>
            </Col>
          </Row>
        </Container>
        <Tabs
          defaultActiveKey="Site Stage"
          id="uncontrolled-tab-example"
          className="mb-3 d-flex mt-2"
          activeKey={activeKey}
          onSelect={handleSelect}
        >
          {(role === 1 || role === 3 || role === 4) && (
            <Tab
              eventKey="Site Stage"
              title={
                <span
                  className="border rounded px-1 fs-8"
                  style={
                    activeKey === 'Site Stage'
                      ? activeTabStyle
                      : inactiveTabStyle
                  }
                >
                  Stages
                </span>
              }
            >
              {activeKey === 'Site Stage' ? (
                <SiteStageTab
                  siteId={id}
                  contractorFullName={contractorFullName}
                  contractorId={
                    typeof contractorId === 'string'
                      ? parseInt(contractorId)
                      : contractorId
                  }
                />
              ) : (
                ''
              )}
            </Tab>
          )}
          {(role === 1 || role === 3 || role === 4) && (
            <Tab
              eventKey="Document"
              title={
                <span
                  className="border rounded px-1 fs-8"
                  style={
                    activeKey === 'Document' ? activeTabStyle : inactiveTabStyle
                  }
                >
                  Document
                </span>
              }
            >
              {' '}
              {activeKey === 'Document' ? (
                <SiteDocumentListTable siteId={id} />
              ) : (
                ''
              )}
            </Tab>
          )}
          <Tab
            eventKey="Inventory"
            title={
              <span
                className="border rounded px-1 fs-8"
                style={
                  activeKey === 'Inventory' ? activeTabStyle : inactiveTabStyle
                }
              >
                Inventory
              </span>
            }
          >
            {activeKey === 'Inventory' ? (
              <InventoryListTable siteId={id} />
            ) : (
              ''
            )}
          </Tab>
          {(role === 1 || role === 4) && (
            <Tab
              eventKey="Material Request"
              title={
                <span
                  className="border rounded px-1 fs-8"
                  style={
                    activeKey === 'Material Request'
                      ? activeTabStyle
                      : inactiveTabStyle
                  }
                >
                  Material Request
                </span>
              }
            >
              {activeKey === 'Material Request' ? (
                <RequestForMaterialListTable siteId={id} />
              ) : (
                ''
              )}
            </Tab>
          )}
          {(role === 1 || role === 3 || role === 4) && (
            <Tab
              eventKey="Site Expenses"
              title={
                <span
                  className="border rounded px-1 fs-8"
                  style={
                    activeKey === 'Site Expenses'
                      ? activeTabStyle
                      : inactiveTabStyle
                  }
                >
                  Expenses
                </span>
              }
            >
              {activeKey === 'Site Expenses' ? (
                <div className="mt-5">
                  <SiteExpenseListTable siteId={id} />
                </div>
              ) : (
                ''
              )}
            </Tab>
          )}
          {(role === 1 || role === 2 || role === 3) && (
            <Tab
              eventKey="Billing"
              title={
                <span
                  className="border rounded px-4 fs-8"
                  style={
                    activeKey === 'Billing' ? activeTabStyle : inactiveTabStyle
                  }
                >
                  Billing
                </span>
              }
            >
              {activeKey === 'Billing' ? (
                <SitBillingTab siteId={id} siteName={siteName} />
              ) : (
                ''
              )}
            </Tab>
          )}
          {(role === 1 || role === 3 || role === 4) && (
            <Tab
              eventKey="Add Staff"
              title={
                <span
                  className="border rounded px-1 fs-8"
                  style={
                    activeKey === 'Add Staff'
                      ? activeTabStyle
                      : inactiveTabStyle
                  }
                >
                  + Staff
                </span>
              }
            >
              {activeKey === 'Add Staff' ? (
                <div className="mt-5">
                  <AddStaff siteId={id} />
                </div>
              ) : (
                ''
              )}
            </Tab>
          )}
          {(role === 1 || role === 3 || role === 4) && (
            <Tab
              eventKey="Attendance"
              title={
                <span
                  className="border rounded px-1 fs-8"
                  style={
                    activeKey === 'Attendance'
                      ? activeTabStyle
                      : inactiveTabStyle
                  }
                >
                  Attendance
                </span>
              }
            >
              {activeKey === 'Attendance' ? (
                <div className="mt-5">
                  <AttendanceListView siteId={id} />
                </div>
              ) : (
                ''
              )}
            </Tab>
          )}
        </Tabs>
        {showAlert && (
          <ChangeContractorModal
            name={'Select New Contractor'}
            show={showAlert}
            handleClose={() => setShowAlert(false)}
            // handleAction={() => console.log('rrr')}
            siteId={siteDetails.id}
          />
        )}
      </div>
    </Container>
  );
};

export default SiteDetails;
