import { faPaperclip, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import Loader from 'helpers/Loader';
import {
  formatDateForAPI,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { FormEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, getService } from 'services/services';

interface Common {
  id: number;
  name: string;
}

interface WorkOrder {
  id: number;
  name: string;
}
interface Contractor {
  id: number;
  first_name: string;
  last_name: string;
}

interface Field {
  wo_id: string;
  site_name: string;
  site_id: string;
  country: string;
  state: string;
  city: string;
  address: string;
  contractor_id: string;
  start_date: string | Date;
  end_date: string | Date;
  budget: string;
  description: string;
  sac_code: string;
  uom: string;
  actual_start_date?: string | Date; // Make optional with '?'
  actual_end_date?: string | Date; // Make optional with '?'
}

const CreateNewSite = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [country, setCountry] = useState<Common[]>([]);
  const [state, setState] = useState<Common[]>([]);
  const [city, setCity] = useState<Common[]>([]);
  const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([]);
  const [contractorList, setContractorList] = useState<Contractor[]>([]);
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState<Field>(() => {
    if (id) {
      return {
        wo_id: '',
        site_name: '',
        site_id: '',
        country: '',
        state: '',
        city: '',
        address: '',
        contractor_id: '',
        start_date: '',
        end_date: '',
        budget: '',
        description: '',
        actual_start_date: '',
        actual_end_date: '',
        sac_code: '',
        uom: ''
      };
    } else {
      return {
        wo_id: '',
        site_name: '',
        site_id: '',
        country: '',
        state: '',
        city: '',
        address: '',
        contractor_id: '',
        start_date: '',
        end_date: '',
        budget: '',
        description: '',
        sac_code: '',
        uom: ''
      };
    }
  });

  const clearDate = (event: any) => {
    event.preventDefault();
    setFields({
      ...fields,
      actual_end_date: ''
    });
  };

  useEffect(() => {
    if (id) {
      fetchCompleteDetails(id);
    }
  }, [id]);

  const fetchCompleteDetails = async (id: any) => {
    const response = await getService(`site_completed/${id}`);
    if (response.status === 200) {
      setFields(prevFields => ({
        ...prevFields,
        wo_id: response.data.wo_id,
        site_name: response.data.site_name,
        site_id: response.data.id,
        contractor_id: response.data.contractor_id,
        start_date: response.data.start_date,
        end_date: response.data.end_date,
        budget: response.data.budget,
        description: response.data.description,
        country: response.data.country,
        state: response.data.state,
        city: response.data.city,
        address: response.data.address,
        actual_start_date: response.data.actual_start_date
          ? response.data.actual_start_date
          : '',
        actual_end_date: response.data.actual_end_date,
        sac_code: response.data.sac_code,
        uom: response.data.uom
      }));
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));

    if (name === 'country') {
      getState(value);
    }

    if (name === 'state') {
      getCity(value);
    }
  };

  useEffect(() => {
    getCountry();
    getWorkOrder();
    getContractor();
  }, []);

  useEffect(() => {
    if (fields.state) {
      getCity(fields.state);
    }
  }, [fields.state]);

  useEffect(() => {
    if (fields.country) {
      getState(fields.country);
    }
  }, [fields.country]);

  const getWorkOrder = async () => {
    const response = await getService('workorder');
    if (response.status === 200) {
      setWorkOrderList(response.data);
    }
  };
  const getContractor = async () => {
    const response = await getService('contractor');
    if (response.status === 200) {
      setContractorList(response.data);
    }
  };
  const getCountry = async () => {
    const response = await getService('country');
    if (response.status === 200) {
      setCountry(response.data);
    }
  };
  const getState = async (country_id: string) => {
    const response = await getService(`state-by-country/${country_id}`);
    if (response.status === 200) {
      setState(response.data);
    }
  };
  const getCity = async (state_id: string) => {
    const response = await getService(`city-by-state/${state_id}`);
    if (response.status === 200) {
      setCity(response.data);
    }
  };

  const handleSave = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    fields.start_date = fields.start_date
      ? formatDateForAPI(fields.start_date)
      : '';
    fields.end_date = formatDateForAPI(fields.end_date);
    const data = jsonToFormData(fields);
    let response: any = {}; // Declare response variable with let instead of const
    if (id) {
      response = await addService(`site/${id}`, data); // Remove const keyword
    } else {
      response = await addService('site', data); // Remove const keyword
    }

    if (response?.status === 200) {
      setLoading(false);
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      if (id && !fields.actual_end_date) {
        navigate('/siteList');
      } else if (id) {
        navigate('/completedSiteList');
      } else {
        navigate('/siteList');
      }
    } else {
      setLoading(false);
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };
  if (loading) return <Loader />;
  return (
    <div>
      <h4 className="mb-4">{id ? 'Update site' : 'Create New Site'}</h4>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectTask" label="WO Name">
                <Form.Select
                  name="wo_id"
                  value={fields['wo_id']}
                  onChange={handleSelect}
                >
                  <option>Select WO Name</option>
                  {workOrderList.length > 0 &&
                    workOrderList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={8}>
              <FloatingLabel controlId="floatingInputGrid" label="Site Name">
                <Form.Control
                  type="text"
                  placeholder="Site Name"
                  name="site_name"
                  value={fields['site_name']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Site ID">
                <Form.Control
                  type="text"
                  placeholder="Site ID"
                  name="site_id"
                  value={fields['site_id']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Site Budget">
                <Form.Control
                  type="number"
                  placeholder="Site Budget"
                  name="budget"
                  value={fields['budget']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectTask" label="Country">
                <Form.Select
                  name="country"
                  value={fields['country']}
                  onChange={handleSelect}
                >
                  <option>Select Country</option>
                  {country.length > 0 &&
                    country.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectPrivacy" label="State">
                <Form.Select
                  name="state"
                  value={fields['state']}
                  onChange={handleSelect}
                >
                  <option>Select State</option>
                  {state.length > 0 &&
                    state.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingSelectPrivacy"
                label="City/Village"
              >
                <Form.Select
                  name="city"
                  value={fields['city']}
                  onChange={handleSelect}
                >
                  <option>Select City</option>
                  {city.length > 0 &&
                    city.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Address">
                <Form.Control
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={fields['address']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectTeam" label="Contractor">
                <Form.Select
                  name="contractor_id"
                  value={fields['contractor_id']}
                  onChange={handleSelect}
                >
                  <option>Select Contractor</option>
                  {contractorList.length > 0 &&
                    contractorList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.first_name} {item.last_name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                value={fields['start_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  setFields({
                    ...fields,
                    start_date: date
                  });
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="Project title"
                        ref={ref}
                        id="startDate"
                      />
                      <label htmlFor="startDate" className="ps-6">
                        Start date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                value={fields['end_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  setFields({
                    ...fields,
                    end_date: date
                  });
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        type="date"
                        placeholder="Deadline"
                        ref={ref}
                        id="deadline"
                      />
                      <label htmlFor="deadline" className="ps-6">
                        Estimated End Date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="SAC Code">
                <Form.Control
                  type="text"
                  placeholder="SAC Code"
                  name="sac_code"
                  value={fields['sac_code']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="UOM">
                <Form.Control
                  type="text"
                  placeholder="UOM"
                  name="uom"
                  value={fields['uom']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            {id && (
              <>
                {' '}
                <Col sm={6} md={4}>
                  <DatePicker
                    value={fields['actual_start_date']}
                    options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                    onChange={([date]) => {
                      setFields({
                        ...fields,
                        actual_start_date: formatDateForAPI(date)
                      });
                    }}
                    render={(_, ref) => {
                      return (
                        <Form.Floating>
                          <Form.Control
                            type="text"
                            placeholder="Project title"
                            ref={ref}
                            id="startDate"
                          />
                          <label htmlFor="startDate" className="ps-6">
                            Actual Start Date
                          </label>
                        </Form.Floating>
                      );
                    }}
                  />
                </Col>
                <Col sm={6} md={4}>
                  <Row>
                    <Col sm={10} md={10}>
                      <DatePicker
                        value={fields['actual_end_date']}
                        options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                        onChange={([date]) => {
                          setFields({
                            ...fields,
                            actual_end_date: formatDateForAPI(date)
                          });
                        }}
                        render={(_, ref) => {
                          return (
                            <Form.Floating>
                              <Form.Control
                                type="date"
                                placeholder="Deadline"
                                ref={ref}
                                id="deadline"
                              />
                              <label htmlFor="deadline" className="ps-6">
                                Actual End Date
                              </label>
                            </Form.Floating>
                          );
                        }}
                      />
                    </Col>
                    <Col sm={2} md={2}>
                      <span
                        onClick={clearDate}
                        style={{
                          position: 'relative',
                          top: 14,
                          right: '60px',
                          cursor: 'pointer'
                        }}
                      >
                        <FontAwesomeIcon icon={faRemove} className="me-sm-2" />
                      </span>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
            <Col xs={12} className="gy-6">
              <FloatingLabel
                controlId="floatingProjectOverview"
                label="Services description"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  name="description"
                  value={fields['description']}
                  onChange={handleChange}
                  style={{ height: '100px' }}
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/siteList">
                  <Button variant="phoenix-primary" className="px-5">
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSave}
                >
                  {id ? 'Submit' : 'Create Site'}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateNewSite;
