// import { useEffect, useRef, useState } from 'react';
// import { Button, Col, Tab, Tabs } from 'react-bootstrap';
// import MaterialBillListTable from './MaterialBillListTable';
// import ExpensesListTable from './ExpensesListTable';
// import {
//   convertNumberToWords,
//   dateFormat,
//   jsonToFormData
// } from 'helpers/utils';
// import { addService } from 'services/services';
// import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
// import Section from 'components/base/Section';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Row } from 'react-bootstrap';
// import {
//   faBagShopping,
//   faDownload,
//   faPrint
// } from '@fortawesome/free-solid-svg-icons';
// import { defaultBreadcrumbItems } from 'data/commonData';
// import { Table } from 'react-bootstrap';

// interface Props {
//   siteId: any;
//   siteName: string;
// }

// interface InvoiceProduct {
//   id: number;
//   material: {
//     name: string;
//     unitdata: {
//       unit_title: string;
//     };
//   };
//   quantity: number;
//   site_rate: number;
//   paid_amount: number;
//   expense_id: string;
//   expenses_date: any;
//   mode_of_payment: string;
// }

// // eslint-disable-next-line react/prop-types
// const SitBillingTab: React.FC<Props> = ({ siteId, siteName }) => {
//   const navigate = useNavigate();
//   const [activeKey, setActiveKey] = useState<string>('Material Bill');
//   const [rowExpenseSelection, setRowExpenseSelection] = useState([]);
//   const [rowMaterialSelection, setRowMaterialSelection] = useState([]);
//   const [invoiceData, setInvoiceData] = useState<InvoiceProduct[]>([]);
//   const [invoice, setInvoice] = useState(false);
//   const [materialBoq, setMaterialBoq] = useState(false);
//   const [expenseBoq, setExpenseBoq] = useState(false);
//   const [role, setRole] = useState(0);
//   const printRef = useRef<HTMLDivElement>(null);

//   const handlePrint = () => {
//     if (printRef.current) {
//       const printContents = printRef.current.innerHTML;
//       const originalContents = document.body.innerHTML;
//       document.body.innerHTML = printContents;
//       window.print();
//       document.body.innerHTML = originalContents;
//       window.location.reload(); // To reload the page after printing
//     }
//   };

//   useEffect(() => {
//     if (localStorage.getItem('token')) {
//       const role = Number(localStorage.getItem('role'));
//       setRole(role);
//     }
//   }, []);

//   let totalQuantity = 0;
//   let totalAmount = 0;

//   const maxSrNoWidth =
//     Math.max(...invoiceData.map((product, index) => String(index + 1).length)) *
//     80; // Assuming each character is roughly 10px wide

//   const handleSelect = (key: string | null) => {
//     if (key) {
//       setActiveKey(key);
//     }
//   };

//   const activeTabStyle = {
//     backgroundColor: '#3874FF',
//     color: 'white',
//     fontSize: '20px'
//   };
//   const inactiveTabStyle = {
//     // backgroundColor: 'white',
//     // color: 'black',
//     fontSize: '20px'
//   };

//   useEffect(() => {
//     setInvoice(false);
//   }, []);

//   const handleGenerateBill = async (type: string) => {
//     const fields = {
//       material_ids:
//         rowMaterialSelection.length > 0 ? rowMaterialSelection.join(',') : '',
//       expense_ids:
//         rowExpenseSelection.length > 0 ? rowExpenseSelection.join(',') : '',
//       site_id: siteId
//       // type: type
//     };
//     if (fields.material_ids !== '' || fields.expense_ids !== '') {
//       const data = jsonToFormData(fields);
//       let response: any = {};
//       if (type == 'contractor') {
//         response = await addService(`contractor_bill`, data);
//       } else {
//         response = await addService(`company_bill`, data);
//       }
//       if (response?.status === 200) {
//         if (response?.data?.material_list.length > 0) {
//           setInvoiceData(response?.data?.material_list);
//           setMaterialBoq(true);
//         } else {
//           setInvoiceData(response?.data?.expense_list);
//           setExpenseBoq(true);
//         }
//         // setInvoiceData(response?.data?.material_list);
//         setInvoice(true);
//         toast.success('SuccessFully Generate', {
//           position: 'top-right',
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: 'light'
//         });
//       }
//     } else {
//       toast.error('Please Select Data', {
//         position: 'top-right',
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: 'light'
//       });
//     }
//   };
//   return (
//     <>
//       {!invoice == true ? (
//         <div>
//           <Tabs
//             defaultActiveKey="Generate Bill"
//             id="uncontrolled-tab-example"
//             className="mb-3 d-flex mt-2"
//             activeKey={activeKey}
//             onSelect={handleSelect}
//           >
//             <Tab
//               eventKey="Material Bill"
//               title={
//                 <span
//                   className="border rounded px-6"
//                   style={
//                     activeKey === 'Material Bill'
//                       ? activeTabStyle
//                       : inactiveTabStyle
//                   }
//                 >
//                   Material Bill
//                 </span>
//               }
//             >
//               <MaterialBillListTable
//                 siteId={siteId}
//                 setRowMaterialSelection={setRowMaterialSelection}
//               />
//             </Tab>
//             <Tab
//               eventKey="Expense"
//               title={
//                 <span
//                   className="border rounded px-6"
//                   style={
//                     activeKey === 'Expense' ? activeTabStyle : inactiveTabStyle
//                   }
//                 >
//                   Expense
//                 </span>
//               }
//             >
//               <ExpensesListTable
//                 siteId={siteId}
//                 setRowExpenseSelection={setRowExpenseSelection}
//               />
//             </Tab>
//           </Tabs>
//           <div style={{ textAlign: 'right' }}>
//             <Button
//               className="mb-6 mx-2"
//               variant="primary"
//               onClick={() => handleGenerateBill('contractor')}
//               // onClick={() => navigate('/companyBoq')}
//             >
//               Contractor BOQ
//             </Button>

//             <Button
//               className="mb-6"
//               variant="primary"
//               onClick={() => handleGenerateBill('company')}
//               // onClick={() => navigate('/companyBoq')}
//             >
//               Company BOQ
//             </Button>
//           </div>
//         </div>
//       ) : (
//         <div className="pt-5 pb-9 bg-body-emphasis dark__bg-gray-1200 border-top">
//           <Section small className="py-0">
//             {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
//             <div className="d-flex gap-2 justify-content-between align-items-end mb-4">
//               <h2 className="mb-0 flex-1">Invoice</h2>
//               <Button
//                 variant="phoenix-secondary"
//                 onClick={() => setInvoice(false)}
//               >
//                 <span className="d-none d-sm-inline-block">Back</span>
//               </Button>
//               <Button variant="phoenix-secondary">
//                 <FontAwesomeIcon icon={faDownload} className="me-sm-2" />
//                 <span className="d-none d-sm-inline-block">
//                   Download Invoice
//                 </span>
//               </Button>
//               <Button variant="phoenix-secondary" onClick={handlePrint}>
//                 <FontAwesomeIcon icon={faPrint} className="me-sm-2" />
//                 <span className="d-none d-sm-inline-block">Print</span>
//               </Button>
//             </div>
//             <div ref={printRef}>
//               <div
//                 className="bg-body dark__bg-gray-1100 p-4 mb-4 rounded-2"
//                 ref={printRef}
//               >
//                 <Row className="g-4">
//                   <Col xs={12} lg={12}>
//                     <Row className=" align-items-center g-0">
//                       <Col xs="auto" lg={6} xl={5}>
//                         <h5 className="mb-0 me-3 text-uppercase">
//                           {siteName}/{siteId} COMPANY BOQ
//                         </h5>
//                       </Col>
//                     </Row>
//                   </Col>
//                 </Row>
//               </div>

//               {/* <EcomInvoiceTable /> */}
//               {materialBoq && (
//                 <Table striped bordered hover>
//                   <colgroup>
//                     <col style={{ width: `${maxSrNoWidth}px` }} />{' '}
//                     {/* Setting the width dynamically */}
//                   </colgroup>
//                   <thead>
//                     <tr>
//                       <th className="text-center">Sr. no</th>
//                       <th>Product</th>
//                       <th className="text-center">Unit</th>
//                       <th className="text-center">Quantity</th>
//                       <th className="text-center">Amount</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {invoiceData.map((product, index) => {
//                       totalQuantity += product.quantity;
//                       totalAmount += product.site_rate;
//                       return (
//                         <tr key={product.id}>
//                           <td className="text-center">{index + 1}</td>
//                           <td>{product.material.name}</td>
//                           <td className="text-center px-1">
//                             {product.material.unitdata.unit_title}
//                           </td>
//                           <td className="text-center">{product.quantity}</td>
//                           <td className="text-center">{product.site_rate}</td>
//                         </tr>
//                       );
//                     })}
//                   </tbody>
//                   <tfoot>
//                     {' '}
//                     {/* Adding tfoot for footer row */}
//                     <tr className="mt-5">
//                       <td colSpan={3}>
//                         Rupees in words:{' '}
//                         {convertNumberToWords(totalAmount)
//                           .charAt(0)
//                           .toUpperCase() +
//                           convertNumberToWords(totalAmount).slice(1)}{' '}
//                         {'rupees only'}
//                       </td>
//                       <td className="text-center">{totalQuantity}</td>
//                       <td className="text-center">{totalAmount}</td>
//                     </tr>
//                   </tfoot>
//                 </Table>
//               )}
//               {expenseBoq && (
//                 <Table striped bordered hover>
//                   <colgroup>
//                     <col style={{ width: `${maxSrNoWidth}px` }} />{' '}
//                     {/* Setting the width dynamically */}
//                   </colgroup>
//                   <thead>
//                     <tr>
//                       <th className="text-center">Sr. no</th>
//                       <th>Expense Type</th>
//                       <th className="text-center">Expense Date</th>
//                       <th className="text-center">Payment Mode</th>
//                       <th className="text-center">Amount</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {invoiceData.map((product, index) => {
//                       // totalQuantity += product.quantity;
//                       totalAmount += product.paid_amount;
//                       return (
//                         <tr key={product.id}>
//                           <td className="text-center">{index + 1}</td>
//                           <td>{product.expense_id}</td>
//                           <td className="text-center px-1">
//                             {dateFormat(product.expenses_date)}
//                           </td>
//                           <td className="text-center">
//                             {product.mode_of_payment == '1'
//                               ? 'Online'
//                               : product.mode_of_payment == '2'
//                               ? 'Cash'
//                               : product.mode_of_payment == '3'
//                               ? 'Cheque'
//                               : 'DD'}
//                           </td>
//                           <td className="text-center">{product.paid_amount}</td>
//                         </tr>
//                       );
//                     })}
//                   </tbody>
//                   <tfoot>
//                     {' '}
//                     {/* Adding tfoot for footer row */}
//                     <tr className="mt-5">
//                       <td colSpan={3}>
//                         Rupees in words:{' '}
//                         {convertNumberToWords(totalAmount)
//                           .charAt(0)
//                           .toUpperCase() +
//                           convertNumberToWords(totalAmount).slice(1)}{' '}
//                         {'rupees only'}
//                       </td>
//                       {/* <td className="text-center">{totalQuantity}</td> */}
//                       <td className="text-center">{''}</td>
//                       <td className="text-center">{totalAmount}</td>
//                     </tr>
//                   </tfoot>
//                 </Table>
//               )}
//               <div className="text-end py-9 border-bottom">
//                 {/* <img className="mb-3" src={phoenixMart} alt="phoenix-mart" /> */}
//                 <h4>Authorized Signatory</h4>
//               </div>

//               <div className="print-footer text-center py-4 mb-9">
//                 <p className="mb-0">
//                   This Invoice is generated by ARK-Project ERP system | 2024 ©{' '}
//                   <a href="https://avark.in//" target="_blank" rel="noreferrer">
//                     Avark
//                   </a>
//                 </p>
//               </div>
//             </div>

//             <div className="d-flex justify-content-between">
//               <button className="btn btn-primary">
//                 <FontAwesomeIcon icon={faBagShopping} className="me-2" />
//                 Browse more items
//               </button>
//               <div>
//                 <button className="btn btn-phoenix-secondary me-2">
//                   <FontAwesomeIcon icon={faDownload} className="me-sm-2" />
//                   <span className="d-none d-sm-inline-block">
//                     Download Invoice
//                   </span>
//                 </button>
//                 <Button variant="phoenix-secondary" onClick={handlePrint}>
//                   <FontAwesomeIcon icon={faPrint} className="me-sm-2" />
//                   <span className="d-none d-sm-inline-block">Print</span>
//                 </Button>
//               </div>
//             </div>
//           </Section>
//         </div>
//       )}
//     </>
//   );
// };

// export default SitBillingTab;

import { useEffect, useRef, useState } from 'react';
import { Button, Col, Tab, Tabs } from 'react-bootstrap';
import MaterialBillListTable from './MaterialBillListTable';
import ExpensesListTable from './ExpensesListTable';
import {
  convertNumberToWords,
  dateFormat,
  jsonToFormData
} from 'helpers/utils';
import { addService } from 'services/services';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Section from 'components/base/Section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-bootstrap';
import {
  faBagShopping,
  faDownload,
  faPrint
} from '@fortawesome/free-solid-svg-icons';
import { defaultBreadcrumbItems } from 'data/commonData';
import { Table } from 'react-bootstrap';

interface Props {
  siteId: any;
  siteName: string;
}

interface InvoiceProduct {
  id: number;
  material: {
    name: string;
    unitdata: {
      unit_title: string;
    };
  };
  quantity: number;
  site_rate: number;
  paid_amount: number;
  expenses_date: any;
  mode_of_payment: string;
  expensemaster: {
    expense_title: string;
  };
}

interface Material {
  id: number;
  name: string;
  unit: number;
  unitdata: {
    id: number;
    unit_title: string;
  };
}

interface Site {
  id: number;
  wo_id: number;
  contractor_id: number;
  site_id: string;
  site_name: string;
}

interface MaterialListItem {
  id: number;
  site_id: number;
  material_id: number;
  unit: number;
  quantity: number;
  rate_per_site: number;
  site_rate: number;
  gst: number;
  tpgst: number;
  is_dispatch: number;
  status: number;
  site: Site;
  material: Material;
}

interface ConsolidatedMaterial {
  name: string;
  // unit: number;
  quantity: number;
  total_site_rate: number;
  unit: string;
}

// eslint-disable-next-line react/prop-types
const SitBillingTab: React.FC<Props> = ({ siteId, siteName }) => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState<string>('Material Bill');
  const [rowExpenseSelection, setRowExpenseSelection] = useState([]);
  const [rowMaterialSelection, setRowMaterialSelection] = useState([]);
  const [invoiceData, setInvoiceData] = useState<InvoiceProduct[]>([]);
  const [invoice, setInvoice] = useState(false);
  const [materialBoq, setMaterialBoq] = useState(false);
  const [expenseBoq, setExpenseBoq] = useState(false);
  const [role, setRole] = useState(0);
  const printRef = useRef<HTMLDivElement>(null);
  const [consolidatedData, setConsolidatedData] = useState<
    ConsolidatedMaterial[]
  >([]);

  const handlePrint = () => {
    if (printRef.current) {
      const printContents = printRef.current.innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload(); // To reload the page after printing
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  let totalQuantity = 0;
  let totalAmount = 0;

  const maxSrNoWidth =
    Math.max(...invoiceData.map((product, index) => String(index + 1).length)) *
    80; // Assuming each character is roughly 10px wide

  const handleSelect = (key: string | null) => {
    if (key) {
      setActiveKey(key);
    }
  };

  const activeTabStyle = {
    backgroundColor: '#3874FF',
    color: 'white',
    fontSize: '20px'
  };
  const inactiveTabStyle = {
    // backgroundColor: 'white',
    // color: 'black',
    fontSize: '20px'
  };

  useEffect(() => {
    setInvoice(false);
  }, []);

  const consolidateMaterials = (
    data: MaterialListItem[]
  ): ConsolidatedMaterial[] => {
    const resultMap: { [key: string]: ConsolidatedMaterial } = {};

    data.forEach(item => {
      const materialName = item.material.name;

      if (!resultMap[materialName]) {
        resultMap[materialName] = {
          name: materialName,
          unit: item.material.unitdata.unit_title,
          quantity: 0,
          total_site_rate: 0
        };
      }

      resultMap[materialName].quantity += item.quantity;
      resultMap[materialName].total_site_rate += item.site_rate;
    });

    return Object.values(resultMap);
  };

  const handleGenerateBill = async (type: string) => {
    const fields = {
      material_ids:
        rowMaterialSelection.length > 0 ? rowMaterialSelection.join(',') : '',
      expense_ids:
        rowExpenseSelection.length > 0 ? rowExpenseSelection.join(',') : '',
      site_id: siteId
      // type: type
    };
    if (fields.material_ids !== '' || fields.expense_ids !== '') {
      const data = jsonToFormData(fields);
      let response: any = {};
      if (type == 'contractor') {
        response = await addService(`contractor_bill`, data);
      } else {
        response = await addService(`company_bill`, data);
      }
      if (response?.status === 200) {
        if (response?.data?.material_list.length > 0) {
          const consolidated = consolidateMaterials(
            response?.data?.material_list
          );
          setConsolidatedData(consolidated);
          // setInvoiceData(response?.data?.material_list);
          setMaterialBoq(true);
        } else {
          setInvoiceData(response?.data?.expense_list);
          setExpenseBoq(true);
        }
        // setInvoiceData(response?.data?.material_list);
        setInvoice(true);
        toast.success('SuccessFully Generate', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
      }
    } else {
      toast.error('Please Select Data', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    }
  };
  return (
    <>
      {!invoice == true ? (
        <div>
          <Tabs
            defaultActiveKey="Generate Bill"
            id="uncontrolled-tab-example"
            className="mb-3 d-flex mt-2"
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Tab
              eventKey="Material Bill"
              title={
                <span
                  className="border rounded px-6"
                  style={
                    activeKey === 'Material Bill'
                      ? activeTabStyle
                      : inactiveTabStyle
                  }
                >
                  Material Bill
                </span>
              }
            >
              <MaterialBillListTable
                siteId={siteId}
                setRowMaterialSelection={setRowMaterialSelection}
              />
            </Tab>
            <Tab
              eventKey="Expense"
              title={
                <span
                  className="border rounded px-6"
                  style={
                    activeKey === 'Expense' ? activeTabStyle : inactiveTabStyle
                  }
                >
                  Expense
                </span>
              }
            >
              <ExpensesListTable
                siteId={siteId}
                setRowExpenseSelection={setRowExpenseSelection}
              />
            </Tab>
          </Tabs>
          <div style={{ textAlign: 'right' }}>
            <Button
              className="mb-6 mx-2"
              variant="primary"
              onClick={() => handleGenerateBill('contractor')}
              // onClick={() => navigate('/companyBoq')}
            >
              Contractor BOQ
            </Button>

            {/* <Button
              className="mb-6"
              variant="primary"
              onClick={() => handleGenerateBill('company')}
              // onClick={() => navigate('/companyBoq')}
            >
              Company BOQ
            </Button> */}
          </div>
        </div>
      ) : (
        <div className="pt-5 pb-9 bg-body-emphasis dark__bg-gray-1200 border-top">
          <Section small className="py-0">
            {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
            <div className="d-flex gap-2 justify-content-between align-items-end mb-4">
              <h2 className="mb-0 flex-1">Invoice</h2>
              <Button
                variant="phoenix-secondary"
                onClick={() => setInvoice(false)}
              >
                <span className="d-none d-sm-inline-block">Back</span>
              </Button>
              <Button variant="phoenix-secondary">
                <FontAwesomeIcon icon={faDownload} className="me-sm-2" />
                <span className="d-none d-sm-inline-block">
                  Download Invoice
                </span>
              </Button>
              <Button variant="phoenix-secondary" onClick={handlePrint}>
                <FontAwesomeIcon icon={faPrint} className="me-sm-2" />
                <span className="d-none d-sm-inline-block">Print</span>
              </Button>
            </div>
            <div ref={printRef}>
              <div
                className="bg-body dark__bg-gray-1100 p-4 mb-4 rounded-2"
                ref={printRef}
              >
                <Row className="g-4">
                  <Col xs={12} lg={12}>
                    <Row className=" align-items-center g-0">
                      <Col xs="auto" lg={6} xl={5}>
                        <h5 className="mb-0 me-3 text-uppercase">
                          {siteName}/{siteId} COMPANY BOQ
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              {/* <EcomInvoiceTable /> */}
              {materialBoq && (
                <Table striped bordered hover>
                  <colgroup>
                    <col style={{ width: `${maxSrNoWidth}px` }} />{' '}
                    {/* Setting the width dynamically */}
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="text-center">Sr. no</th>
                      <th>Product</th>
                      <th className="text-center">Unit</th>
                      <th className="text-center">Quantity</th>
                      <th className="text-center">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {consolidatedData.map((product, index) => {
                      totalQuantity += product.quantity;
                      totalAmount += product.total_site_rate;
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{product.name}</td>
                          <td className="text-center px-1">{product.unit}</td>
                          <td className="text-center">{product.quantity}</td>
                          <td className="text-center">
                            {product.total_site_rate}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    {' '}
                    {/* Adding tfoot for footer row */}
                    <tr className="mt-5">
                      <td colSpan={3}>
                        Rupees in words:{' '}
                        {convertNumberToWords(totalAmount)
                          .charAt(0)
                          .toUpperCase() +
                          convertNumberToWords(totalAmount).slice(1)}{' '}
                        {'rupees only'}
                      </td>
                      <td className="text-center">{totalQuantity}</td>
                      <td className="text-center">{totalAmount}</td>
                    </tr>
                  </tfoot>
                </Table>
              )}
              {expenseBoq && (
                <Table striped bordered hover>
                  <colgroup>
                    <col style={{ width: `${maxSrNoWidth}px` }} />{' '}
                    {/* Setting the width dynamically */}
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="text-center">Sr. no</th>
                      <th>Expense Type</th>
                      <th className="text-center">Expense Date</th>
                      <th className="text-center">Payment Mode</th>
                      <th className="text-center">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData.map((product, index) => {
                      // totalQuantity += product.quantity;
                      totalAmount += product.paid_amount;
                      return (
                        <tr key={product.id}>
                          <td className="text-center">{index + 1}</td>
                          <td>{product.expensemaster.expense_title}</td>
                          <td className="text-center px-1">
                            {dateFormat(product.expenses_date)}
                          </td>
                          <td className="text-center">
                            {product.mode_of_payment == '1'
                              ? 'Online'
                              : product.mode_of_payment == '2'
                              ? 'Cash'
                              : product.mode_of_payment == '3'
                              ? 'Cheque'
                              : 'DD'}
                          </td>
                          <td className="text-center">{product.paid_amount}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    {' '}
                    {/* Adding tfoot for footer row */}
                    <tr className="mt-5">
                      <td colSpan={3}>
                        Rupees in words:{' '}
                        {convertNumberToWords(totalAmount)
                          .charAt(0)
                          .toUpperCase() +
                          convertNumberToWords(totalAmount).slice(1)}{' '}
                        {'rupees only'}
                      </td>
                      {/* <td className="text-center">{totalQuantity}</td> */}
                      <td className="text-center">{''}</td>
                      <td className="text-center">{totalAmount}</td>
                    </tr>
                  </tfoot>
                </Table>
              )}
              <div className="text-end py-9 border-bottom">
                {/* <img className="mb-3" src={phoenixMart} alt="phoenix-mart" /> */}
                <h4>Authorized Signatory</h4>
              </div>

              <div className="print-footer text-center py-4 mb-9">
                <p className="mb-0">
                  This Invoice is generated by ARK-Project ERP system | 2024 ©{' '}
                  <a href="https://avark.in//" target="_blank" rel="noreferrer">
                    Avark
                  </a>
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <button className="btn btn-primary">
                <FontAwesomeIcon icon={faBagShopping} className="me-2" />
                Browse more items
              </button>
              <div>
                <button className="btn btn-phoenix-secondary me-2">
                  <FontAwesomeIcon icon={faDownload} className="me-sm-2" />
                  <span className="d-none d-sm-inline-block">
                    Download Invoice
                  </span>
                </button>
                <Button variant="phoenix-secondary" onClick={handlePrint}>
                  <FontAwesomeIcon icon={faPrint} className="me-sm-2" />
                  <span className="d-none d-sm-inline-block">Print</span>
                </Button>
              </div>
            </div>
          </Section>
        </div>
      )}
    </>
  );
};

export default SitBillingTab;
