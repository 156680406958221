import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import { getService } from 'services/services';
import { validationErrorFromAPI } from 'helpers/utils';
import { FloatingLabel } from 'react-bootstrap';
import JsonToCsvButton from 'components/JsonToCsvButton';
import Loader from 'helpers/Loader';

interface StaffList {
  staff_detail: {
    id: number;
    first_name: string;
    last_name: string;
    working_hours: number;
  };
  total_time: string;
  over_time: string;
  total_day_amount: number;
  day_amount: number;
  ot_time_amt: number;
}

const StaffAttendance = () => {
  const navigate = useNavigate();
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [staffList, setStaffList] = useState([]);
  const [role, setRole] = useState(0);
  const [year, setYear] = useState<any>(null);
  const [month, setMonth] = useState<any>(null);
  const [siteId, setSiteId] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const fetch = async () => {
    setLoading(true);
    const response = await getService('staff_attendance_month_report/0/0/0');
    if (response.status === 200) {
      setLoading(false);
      setStaffList(response.data);
    } else if (response?.status === 500) {
      setLoading(false);
      validationErrorFromAPI(response.data);
    }
  };
  useEffect(() => {
    fetch();
    fetchSiteList();
    getCurrentMonthNumber();
  }, []);

  const getCurrentMonthNumber = () => {
    const today = new Date();
    setMonth(today.getMonth() + 1);
    setYear(today.getFullYear());
  };

  const fetchSiteList = async () => {
    const response = await getService('site');
    if (response.status === 200) {
      setCompanyList(response.data);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleMonthChange = async (id: any) => {
    if (id) {
      setMonth(id);
      const response = await getService(
        `staff_attendance_month_report/${siteId ? siteId : 0}/${id}/${
          year ? year : 0
        }`
      );
      if (response.status === 200) {
        setStaffList(response.data);
      }
    } else {
      `staff_attendance_month_report/${siteId ? siteId : 0}/${
        month ? month : 0
      }/${year ? year : 0}`;
    }
  };

  const handleYearChange = async (id: any) => {
    if (id) {
      setYear(id);
      const response = await getService(
        `staff_attendance_month_report/${siteId ? siteId : 0}/${
          month ? month : 0
        }/${id}`
      );
      if (response.status === 200) {
        setStaffList(response.data);
      }
    } else {
      `staff_attendance_month_report/${siteId ? siteId : 0}/${
        month ? month : 0
      }/${year ? year : 0}`;
    }
  };

  const handleCompanyChange = async (id: any) => {
    if (id) {
      setSiteId(id);
      const response = await getService(
        `staff_attendance_month_report/${id}/${month ? month : 0}/${
          year ? year : 0
        }`
      );
      if (response.status === 200) {
        setStaffList(response.data);
      }
    } else {
      `staff_attendance_month_report/${siteId ? siteId : 0}/${
        month ? month : 0
      }/${year ? year : 0}`;
    }
  };

  const convertMintoHours = (minites: string) => {
    const Hours = Math.round(Number(minites) / 60);
    return Hours;
  };

  const dayCalculate = (
    workingH: number,
    totalTime: string,
    overTime: string
  ) => {
    const Day = Math.round(
      (Number(totalTime) - Number(overTime)) / 60 / workingH
    );
    return Day;
  };

  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const getMonth = (numericMonth: any): string => {
    if (numericMonth === null || numericMonth < 1 || numericMonth > 12) {
      return 'Invalid Month';
    }
    return monthList[numericMonth - 1];
  };

  const filteredData = staffList.map((data: any) => ({
    Staff_id: data.staff_detail.id,
    Staff_Name: `${data.staff_detail.first_name} ${data.staff_detail.last_name}`,
    Working_Hours: data.total_time,
    Overtime_Hours: data.over_time,
    No_Of_Days: data.over_time,
    Current_Salary: data.total_day_amount,
    Monthly_salary: data.staff_detail.monthly_salary,
    aadhar_no: data.staff_detail.aadhar_no,
    account_no: data.staff_detail.account_no,
    bank_name: data.staff_detail.bank_name,
    ifsc_code: data.staff_detail.ifsc_code,
    joining_date: data.staff_detail.joining_date,
    mobile_no: data.staff_detail.mobile_no,
    overtime_rate: data.staff_detail.overtime_rate,
    paid_leave: data.staff_detail.paid_leave,
    medical_leave: data.staff_detail.medical_leave,
    address: data.staff_detail.address,
    email: data.staff_detail.email,
    working_hours: data.staff_detail.working_hours,
    month: getMonth(month),
    year: year
  }));

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<StaffList>[] = [
    {
      accessorKey: 'id',
      header: 'Staff ID',
      cell: ({ row: { original } }) => {
        const { id } = original.staff_detail;
        return <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{id}</p>;
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'first_name',
      header: 'Staff Name',
      cell: ({ row: { original } }) => {
        const { first_name, last_name, id } = original.staff_detail;
        return (
          <Link to={`/attendance/${id}`}>
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {`${first_name} ${last_name}`}
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'total_time',
      header: 'Working hours',
      cell: ({ row: { original } }) => {
        const { total_time } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {convertMintoHours(total_time)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'over_time',
      header: 'Overtime hours',
      cell: ({ row: { original } }) => {
        const { over_time } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {convertMintoHours(over_time)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    {
      accessorKey: 'invoice_date',
      header: 'No of Days',
      cell: ({ row: { original } }) => {
        const { working_hours } = original.staff_detail;
        const { total_time, over_time } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {dayCalculate(working_hours, total_time, over_time)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    },
    // {
    //   accessorKey: 'invoice_date',
    //   header: 'Bonus',
    //   cell: ({ row: { original } }) => {
    //     const { total_day_amount } = original;
    //     return (
    //       <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
    //         {/* {Math.round(total_day_amount)} */}
    //       </p>
    //     );
    //   },
    //   meta: {
    //     headerProps: {
    //       style: { width: '20%', minWidth: '200px' }
    //     },
    //     cellProps: { className: 'white-space-nowrap' }
    //   }
    // },
    {
      accessorKey: 'invoice_date',
      header: 'Current Salary',
      cell: ({ row: { original } }) => {
        const { total_day_amount } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
            {Math.round(total_day_amount)}
          </p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '20%', minWidth: '200px' }
        },
        cellProps: { className: 'white-space-nowrap' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: staffList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });
  if (loading) return <Loader />;
  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-5">Staff Attendance</h2>
        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto">
                <FloatingLabel controlId="floatingSelectTask" label="Site">
                  <Form.Select
                    name="Site"
                    onChange={e => handleCompanyChange(e.target.value)}
                  >
                    <option value="0">Select Site</option>
                    {companyList.length > 0 &&
                      companyList.map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {`${item.site_name}`}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel
                  controlId="floatingSelectTask"
                  label="Month Filter"
                >
                  <Form.Select
                    name="Month Filter"
                    value={month}
                    onChange={e => handleMonthChange(e.target.value)}
                  >
                    <option value="0">Select Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel
                  controlId="floatingSelectTask"
                  label="Year Filter"
                >
                  <Form.Select
                    name="Year Filter"
                    value={year}
                    onChange={e => handleYearChange(e.target.value)}
                  >
                    <option value="0">Select Month</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2027">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                {/* <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button> */}
                <JsonToCsvButton
                  data={filteredData}
                  filename="Inventory Report.csv"
                />
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default StaffAttendance;
