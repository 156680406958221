import { useEffect, useState } from 'react';
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import SiteStageListTable from './SiteStageListTable';
import { addService, getService } from 'services/services';
import Select, { ActionMeta, MultiValue } from 'react-select';
import { validationErrorFromAPI } from 'helpers/utils';
import { toast } from 'react-toastify';
import SiteStageChart from './SiteStageChart';

interface Stage {
  id: number;
  stage_name: string;
}

interface Option {
  value: string; // Ensure id is of type string
  label: string;
}

interface Props {
  siteId: any; // Define the prop type
  contractorFullName?: string; // Define the prop type
  contractorId: number; // Define the prop type
}

type ValueType = Option | Option[] | null;
const SiteStageTab: React.FC<Props> = props => {
  // Access the id prop
  // eslint-disable-next-line react/prop-types
  const { siteId, contractorFullName, contractorId } = props;
  const [stageList, setStageList] = useState<Option[]>([]);
  const [activeKey, setActiveKey] = useState<string>('List');
  const [selectedOption, setSelectedOption] = useState<ValueType>(null);
  const [role, setRole] = useState(0);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const handleChange = (
    newValue: MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    setSelectedOption(newValue as ValueType);
  };

  const handleSelect = (key: string | null) => {
    if (key) {
      setActiveKey(key);
    }
  };

  const activeTabStyle = {
    backgroundColor: '#3874FF',
    color: 'white',
    fontSize: '20px'
  };
  const inactiveTabStyle = {
    // backgroundColor: 'white',
    // color: 'black',
    fontSize: '20px'
  };

  const fetch = async () => {
    const response = await getService('stage');
    if (response.status === 200) {
      const transformedArray: Option[] = response.data.map((item: Stage) => ({
        value: item.id,
        label: item.stage_name
      }));
      setStageList(transformedArray);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const handleSave = async () => {
    if (selectedOption == null) {
      toast.error('No options selected');
      return;
    }

    // Ensure selectedOption is an array
    const optionsArray = Array.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];

    if (optionsArray.length == 0) {
      toast.error('No options selected');
      return;
    }

    // Convert selectedOption array to comma-separated string
    const selectedValues = optionsArray.map(option => option.value).join(',');
    // console.log('Selected values:', selectedValues);

    const formData = new FormData();
    formData.append('site_id', siteId);
    formData.append('stage_id', selectedValues);

    let response: any = {};
    // if (id) {
    //   response = await addService(`site_stage_m/${id}`, formData);
    // } else {
    response = await addService(`site_stage_m`, formData);
    // }
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      // navigate('/material');
    }
  };
  return (
    <div>
      {/* {role === 1 && (
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <Select
              isMulti
              value={selectedOption}
              onChange={handleChange}
              options={stageList}
              placeholder="Select stages..."
              className="react-select-container"
              styles={{
                container: base => ({
                  ...base,
                  width: '100%'
                })
              }}
            />
          </Col>
          <Col xs={12} md={6}>
            <Button onClick={handleSave}>Add Stage</Button>
          </Col>
        </Row>
      )} */}
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="List"
            id="uncontrolled-tab-example"
            className="mb-3 d-flex mt-2"
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Tab
              eventKey="List"
              title={
                <span
                  className="border rounded px-6"
                  style={
                    activeKey === 'List' ? activeTabStyle : inactiveTabStyle
                  }
                >
                  List
                </span>
              }
            >
              <SiteStageListTable
                siteId={siteId}
                contractorFullName={contractorFullName || ''}
                contractorId={contractorId ?? ''}
              />
            </Tab>
            <Tab
              eventKey="chat"
              title={
                <span
                  className="border rounded px-6"
                  style={
                    activeKey === 'chat' ? activeTabStyle : inactiveTabStyle
                  }
                >
                  chart
                </span>
              }
            >
              <SiteStageChart siteId={siteId} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default SiteStageTab;
