import Button from 'components/base/Button';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'components/base/DatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FormEvent, useEffect, useState } from 'react';
import { addService, getService } from 'services/services';
import {
  formatDateForAPI,
  jsonToFormData,
  validationErrorFromAPI
} from 'helpers/utils';
import { toast } from 'react-toastify';
import Loader from 'helpers/Loader';

interface Industry {
  id: number;
  name: string;
}
interface Company {
  id: number;
  name: string;
  industry_id: string;
}

interface Field {
  industry_id: string;
  wo_id: string;
  name: string;
  start_date: string | Date;
  end_date: string | Date;
  wo_amount: string | number;
  comments: string;
  company_id: string;
}

const CreateWorkOrder = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [industryList, setIndustryList] = useState<Industry[]>([]);
  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState<Field>({
    company_id: '',
    industry_id: '',
    wo_id: '',
    name: '',
    start_date: '',
    end_date: '',
    wo_amount: '',
    comments: ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));

    if (name === 'company_id') {
      const selectedCompany = companyList.find(
        company => company.id === parseInt(value)
      );
      if (selectedCompany) {
        const industryId = selectedCompany.industry_id;
        setFields(prevFields => ({
          ...prevFields,
          industry_id: industryId
        }));
      }
    }
  };

  useEffect(() => {
    fetchIndustry();
    fetchCompany();
  }, []);

  const fetchIndustry = async () => {
    const response = await getService('industrie');
    if (response.status === 200) {
      setIndustryList(response.data);
    }
  };

  const fetchCompany = async () => {
    const response = await getService('company');
    if (response.status === 200) {
      setCompanyList(response.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchContractorDetails(id);
    }
  }, [id]);

  const fetchContractorDetails = async (id: any) => {
    const response = await getService(`workorder/${id}`);
    if (response.status === 200) {
      setFields(prevFields => ({
        ...prevFields,
        company_id: response.data[0].company_id,
        industry_id: response.data[0].industry_id,
        wo_id: response.data[0].wo_id,
        name: response.data[0].name,
        start_date: response.data[0].start_date,
        end_date: response.data[0].end_date,
        wo_amount: response.data[0].wo_amount,
        comments: response.data[0].comments
      }));
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleSave = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();

    fields.start_date = formatDateForAPI(fields.start_date);
    fields.end_date = formatDateForAPI(fields.end_date);
    const data = jsonToFormData(fields);
    let response: any = {};
    if (id) {
      response = await addService(`workorder/${id}`, data);
    } else {
      response = await addService(`workorder`, data);
    }
    if (response?.status === 200) {
      setLoading(false);
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      navigate('/workOrder');
    } else if (response?.status === 500) {
      setLoading(false);
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };
  if (loading) return <Loader />;
  return (
    <div>
      <h3 className="mb-4">{id ? 'Edit' : 'Add'} Work Order</h3>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={4} md={4}>
              <FloatingLabel controlId="floatingSelectPrivacy" label="Client">
                <Form.Select
                  name="company_id"
                  value={fields['company_id']}
                  onChange={handleSelect}
                >
                  <option>Select Client</option>
                  {companyList.length > 0 &&
                    companyList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={8}>
              <FloatingLabel controlId="floatingSelectPrivacy" label="Industry">
                <Form.Select
                  name="industry_id"
                  value={fields['industry_id']}
                  onChange={handleSelect}
                  disabled
                >
                  <option>Select Industry</option>
                  {industryList.length > 0 &&
                    industryList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="WO Number">
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={fields['name']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="WO ID">
                <Form.Control
                  type="text"
                  placeholder="Mobile Numbar"
                  name="wo_id"
                  value={fields['wo_id']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="WO value">
                <Form.Control
                  type="text"
                  placeholder="wo_amount"
                  name="wo_amount"
                  value={fields['wo_amount']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                // value={selectedEndDate}
                value={fields['start_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  setFields({
                    ...fields,
                    start_date: date
                  });
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        placeholder="Project title"
                        ref={ref}
                        id="startDate"
                      />
                      <label htmlFor="startDate" className="ps-6">
                        Work Order date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                value={fields['end_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  setFields({
                    ...fields,
                    end_date: date
                  });
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        placeholder="Project title"
                        ref={ref}
                        id="startDate"
                        name="end_date"
                        // value={fields['end_date']}
                        onChange={handleChange}
                      />
                      <label htmlFor="startDate" className="ps-6">
                        Estimated End date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>

            <Col xs={12} className="gy-6">
              <FloatingLabel
                controlId="floatingProjectOverview"
                label="Comments"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: '100px' }}
                  name="comments"
                  value={fields['comments']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to="/workOrder">
                  <Button variant="phoenix-primary" className="px-5">
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSave}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateWorkOrder;
