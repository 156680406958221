import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, SetStateAction, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import { addService, deleteService, getService } from 'services/services';
import Avatar from 'components/base/Avatar';
import { baseLocalURL } from 'config';
import Loader from 'helpers/Loader';

interface Industry {
  id: number;
  siteinventory: {
    material: {
      name: string;
    };
  };
  material: {
    name: string;
    image: any;
  };
  tpgst: number;
  gst: number;
  site_rate: number;
  rate_per_site: number;
  quantity: number;
  status: string;
  is_dispatch: string;
  challan_m: {
    challan: {
      challan_no: string;
    };
  };
}

interface Field {
  site_id: string;
  material_id: string;
  unit_id: string;
  quantity?: string;
  rate_per_site?: any;
  site_rate?: any;
  gst?: any;
  tpgst?: any;
  status: string;
}

interface Props {
  siteId: any; // Define the prop type
  setRowMaterialSelection: any;
}
const MaterialBillListTable: React.FC<Props> = props => {
  // eslint-disable-next-line react/prop-types
  const { siteId, setRowMaterialSelection } = props;

  const navigate = useNavigate();
  const [inventryList, setInventryList] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetch = async () => {
    setLoading(true);
    const response = await getService(
      `site_inventory_by_filter_id/${siteId}/${0}/${0}`
    );
    if (response.status === 200) {
      setLoading(false);
      setInventryList(response.data);
    } else if (response.status === 500) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Industry>[] = [
    {
      accessorKey: 'material.name',
      header: 'Material Name',
      cell: ({ row: { original } }) => {
        const { material } = original;
        if (!material) {
          return null; // Handle case where material is null or undefined
        }

        const { name: materialName, image: materialImage } = material;

        return (
          <Link to="#!" className="d-flex align-items-center text-body">
            <Avatar
              src={
                materialImage ? `${baseLocalURL}/public/${materialImage}` : ''
              }
              size="m"
            />
            <p className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {materialName}
            </p>
          </Link>
        );
      },
      meta: {
        headerProps: {
          className: 'px-1'
        },
        cellProps: {
          className: 'align-middle white-space-nowrap py-2'
        }
      }
    },
    {
      accessorKey: 'material.unitdata.unit_title',
      header: 'Unit',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body' }
      }
    },
    {
      accessorKey: 'material.current_stock',
      header: 'Current Stock',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-center text-body-tertiary' }
      }
    },
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      meta: {
        headerProps: {
          style: { width: '10%' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'rate_per_site',
      header: 'Rate',
      meta: {
        headerProps: {
          style: { width: '10%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'gst',
      header: 'GST',
      cell: ({ row: { original } }) => {
        const { gst } = original;
        return <p className="text-body-emphasis fw-semibold">{gst}%</p>;
      },
      meta: {
        headerProps: {
          style: { width: '10%' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'tpgst',
      header: 'Amount',
      meta: {
        headerProps: {
          style: { width: '10%' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'status',
      header: 'Approval Status',
      cell: ({ row: { original } }) => {
        const { id, status } = original;
        return (
          <div key={id + status}>
            {status == '1' ? (
              <p className="mb-0 ms-3 fw-semibold text-info">
                Approval Pending
              </p>
            ) : status == '2' ? (
              <p className="mb-0 ms-3 fw-semibold text-success">
                Approve to Main
              </p>
            ) : status == '3' ? (
              <p className="mb-0 ms-3 fw-semibold text-warning">
                Approve to Local
              </p>
            ) : status == '4' ? (
              <p className="mb-0 ms-3 fw-semibold text-danger">Reject</p>
            ) : (
              ''
            )}
          </div>
        );
      },
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'challan_m.challan.challan_no',
      header: 'Challan No.',
      meta: {
        headerProps: {
          style: { width: '10%' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-end text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'status',
      header: 'Dispatch Status',
      cell: ({ row: { original } }) => {
        const { is_dispatch } = original;
        return <>{is_dispatch == '1' ? 'Dispatch Done' : 'Dispatch Pending'}</>;
      },
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-end'
        },
        cellProps: {
          className: 'text-end text-body-tertiary'
        }
      }
    }
  ];

  const table = useAdvanceTable({
    data: inventryList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    enableRowSelection: (row: { original: { status: null } }) =>
      row.original?.status == 2 || row.original?.status == 3 ? true : false
  });

  useEffect(() => {
    if (table.getGroupedSelectedRowModel().rows.length > 0) {
      const selectedIDs: number[] = [];
      table.getGroupedSelectedRowModel().rows.map(row => {
        selectedIDs.push(row.original.id);
      });
      setRowMaterialSelection(selectedIDs);
    }
  }, [table.getGroupedSelectedRowModel()]);

  // console.log(table.getSelectedRowModel().rows) //get full client-side selected rows
  // console.log(table.getFilteredSelectedRowModel().rows) //get filtered client-side selected rows
  // console.log('======', table.getGroupedSelectedRowModel().rows); //get grouped client-side selected rows
  if (loading) return <Loader />;

  return (
    <div>
      <div className="mb-9">
        <h3 className="mb-5">Material</h3>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>

              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            {inventryList.length === 0 ? (
              <h4 className="text-center py-2">Data not found</h4>
            ) : (
              <MembersTable />
            )}
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default MaterialBillListTable;
